import { useEffect } from "react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useLoadZendesk } from "src/common/hooks/useLoadZendesk";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import { useAccessToken } from "src/common/authorisation";
import { CreditorResource } from "@gocardless/api/dashboard/types";
declare global {
  interface Window {
    Zendesk: (action: string) => void | boolean;
    zendeskSettings?: {
      app_id: string;
      name: string;
      email: string;
      user_hash: string;
      user_id: string;
      language_override: string;
      hide_default_launcher?: boolean;
    };
  }
}

interface ZendeskProps {
  children: React.ReactNode;
  creditor: CreditorResource | undefined;
}

const Zendesk: React.FC<ZendeskProps> = (props) => {
  const [details] = useAccessToken();
  // call useUserShowSelf with shouldMakeRequest=false if user is not logged in.
  const { data } =
    useUserShowSelf(details !== undefined && details !== null) || {};
  const { loadZendesk } = useLoadZendesk();
  const { isVariationOn, clientReady } = useOptimizelyVariation({
    flag: OptimizelyFlag.BI_ZENDESK_MIGRATION,
  });

  useEffect(() => {
    if (data && data.users) {
      if (clientReady && isVariationOn) {
        loadZendesk(data?.users, props?.creditor);
      }
    }
  }, [
    clientReady,
    isVariationOn,
    data,
    data?.users,
    loadZendesk,
    props?.creditor,
  ]);

  return <>{props.children}</>;
};

export default Zendesk;
