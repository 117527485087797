import { useState } from "react";
import { useLocalStorage } from "src/common/hooks/useLocalStorage/useLocalStorage";
import { SetupPaymentsEnabled } from "src/components/routes/SetupPayments/SetupPaymentsEnabled";

import { useSegment } from "../third-parties/Segment/useSegment";

import TopBanner from "./TopBanner";
import { VerificationConfig } from "./VerificationBannerConfig";
import useVerificationBanner, {
  VerificationBannerEvents,
} from "./useVerificationBanner";

const onCloseClick = (
  forIframe: boolean,
  _sendSegmentEvent: (name: string, params?: {}) => void,
  emitEvent: (name: VerificationBannerEvents) => void,
  onTopBannerClosed: () => void
) => {
  if (forIframe) {
    // postMessage used to communicate with enterprise dashboard
    emitEvent(VerificationBannerEvents.CLOSE_CLICKED);
  }
  onTopBannerClosed();
};

const VerificationBanner = ({
  forIframe,
}: {
  forIframe: boolean;
}): JSX.Element | null => {
  const { sendEvent } = useSegment();
  const { getVariant, remainingDays, digits, emitEvent } =
    useVerificationBanner();

  const [openExtendDiscountDialog, toggleExtendDiscountDialog] =
    useState(false);
  const [topBannerClosed, setTopBannerClosed] = useLocalStorage(
    "gc.top-banner-closed",
    JSON.stringify(false)
  );

  function onTopBannerClose() {
    onCloseClick(forIframe, sendEvent, emitEvent, () => {
      setTopBannerClosed(JSON.stringify(true));
    });
  }

  const content = ({ isFPJEnabled }: { isFPJEnabled: boolean }) => {
    const variant = getVariant(isFPJEnabled);

    // we need an early return if the variant is null
    // we also shouldn't emit any event to enterprise dashboard
    // to avoid displaying a blank banner
    if (!variant || JSON.parse(topBannerClosed)) {
      return null;
    }

    if (forIframe) {
      emitEvent(VerificationBannerEvents.VERIFICATION_BANNER_LOADED);
    }

    return (
      <TopBanner
        {...VerificationConfig({
          variant,
          remainingDays,
          digits,
          openExtendDiscountDialog,
          toggleExtendDiscountDialog,
          forIframe,
          emitEvent,
          sendSegmentEvent: sendEvent,
        })}
        onTopBannerClose={onTopBannerClose}
      />
    );
  };

  return (
    <SetupPaymentsEnabled defaultNode={content({ isFPJEnabled: false })}>
      {content({ isFPJEnabled: true })}
    </SetupPaymentsEnabled>
  );
};

export default VerificationBanner;
