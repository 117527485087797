import {
  AccessTokenCreateRequestBodyScope,
  UserResource,
} from "@gocardless/api/dashboard/types";
import { getConfig } from "src/common/config";
import { loadIntercomScript } from "src/vendor/intercom";

export const useLoadIntercom = () => {
  const loadIntercom = (
    user: UserResource | undefined,
    scope?: AccessTokenCreateRequestBodyScope | undefined
  ) => {
    const intercomAppId = getConfig().client.intercom.appId;
    if (!window) {
      return;
    }

    if (!user) {
      return;
    }

    if (!intercomAppId) {
      return;
    }

    /*
     * We only sync data for admin users from payment-service
     * to intercom, and we have a hard limit of 70K active users.
     */
    if ((user.scope || scope) !== AccessTokenCreateRequestBodyScope.Admin) {
      return;
    }

    window.intercomSettings = {
      app_id: intercomAppId,
      user_id: user.id || "",
      // We add a user hash so that users can't impersonate other users
      // see: https://developers.intercom.com/installing-intercom/docs/enable-identity-verification-on-your-web-product
      user_hash: user.intercom_user_hash || "",
      name: user.family_name + " " + user.given_name,
      email: user.email || "",
      language_override: user.language || "en",
    };

    loadIntercomScript(intercomAppId);

    return () => {
      if ("Intercom" in window && typeof window.Intercom === "function") {
        window.Intercom("shutdown");
      }
    };
  };

  return {
    loadIntercom,
  };
};
